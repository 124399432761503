<template>
  <b-table bordered hover :items="items" :fields="fields">
    <template #cell(posicion)="row">
      <div v-if="row.item.posicion" class="cell-posicion">
        <p @click="openEditPosicion(row.item)">{{ row.item.posicion }}</p>
      </div>
      <div v-else class="cell-posicion">
        <p @click="openEditPosicion(row.item)">sin posicion</p>
      </div>
    </template>
    <template #cell(destacado)="row" v-if="showDestacado">
      <b-overlay :show="loading" rounded="sm">
        <div class="cell-posicion" @click="handleChangeDestacado(row.item)">
          <b-form-checkbox disabled v-model="row.item.destacado" />
        </div>
      </b-overlay>
    </template>
    <template #cell(acciones)="row">
      <div>
        <b-icon
          @click="
            $router.push({
              name: toEditName,
              params: { id: row.item.id },
            })
          "
          v-b-tooltip.hover
          title="actualizar"
          icon="pencil"
        ></b-icon>
        <b-icon
          class="ml-2"
          @click="handleDeleteProyecto(row.item.id)"
          v-b-tooltip.hover
          title="eliminar"
          icon="trash"
        ></b-icon>
      </div>
    </template>
  </b-table>
</template>

<script>

import {FIELDS_TABLA_PROYECTO} from '@/constants/proyecto'

export default {
    props:{
        openEditPosicion:{},
        handleChangeDestacado:{},
        handleDeleteProyecto:{},
        items:{},
        loading:{},
        showDestacado:{
          default:true,
        },
        toEditName:{
          default:'Actualizar Proyecto',
        }
    },
    computed:{
      
      fields(){

        const fields = JSON.parse(JSON.stringify(FIELDS_TABLA_PROYECTO))

        if(this.showDestacado) return fields

        return fields.filter(e =>e.key!=="destacado")
        
      }

    }
}
</script>

<style scoped>
.cell-posicion {
  cursor: pointer;
  text-align: center;
}
</style>
