<template>
    <div>
      <div class="container d-flex justify-content-between">
        <div>
          <h5>{{ title }}</h5>
        </div>
        <div>
          <b-button :to="{ name: toName }">Agregar</b-button>
        </div>
      </div>
      <hr />
      <slot />
    </div>
  </template>
  
  <script>
  export default {
    props:{
        title:{},
        toName:{},
    }
  };
  </script>
  