<template>
  <b-modal
    v-model="modalEditPosicion"
    hide-footer
    @hidden="$emit('hidden')"
    @input="$emit('input',modalEditPosicion)"
  >
    <b-overlay :show="loading" rounded="sm">
      <form @submit.prevent="handleUpdatePosicion()">
        <b-form-group label="Posicion" label-for="nombre">
          <b-form-input
            id="posicion"
            v-model.number="input"
            type="number"
            required
            @input="$emit('onChangePosicion',input)"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary"> Guardar </b-button>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
    props:{
        loading:{},
        value:{},
        handleUpdatePosicion:{},
        posicion:{},
    },
    data(){
        return {
            modalEditPosicion:this.value,
            input:this.posicion,
        }
    }
};
</script>

<style>
</style>