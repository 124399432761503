<template>
    <BaseList title="Propiedades" toName='Nueva Propiedad'>
      <tabla-propiedades />
    </BaseList>
  </template>
  
  <script>
  import TablaPropiedades from "@/components/propiedades/table.vue";
  import BaseList from '@/components/shared/BaseList'
  
  export default {
    components: {
      TablaPropiedades,
      BaseList,
    },
  };
  </script>
  