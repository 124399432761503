export const MSG_SWAL_POSICION_ACTUALIZADA = {
    title: "Actualizado",
    text: "Posicion actualizada",
    icon: "success",
}

export const MSG_SWAL_POSICION_ACTUALIZADA_ERROR = {
    title: "Error",
    text: "No se pudo actualizar la posicion",
    icon: "error",
}

export const MSG_SWAL_ELIMINAR = {
    title: "¿Estás seguro?",
    text: "¡No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "¡Sí, eliminar!",
  }