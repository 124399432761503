<template>
    <div>
      <TablaProyectos
        :items="propiedades"
        :openEditPosicion="openEditPosicion"
        :handleDeleteProyecto="handleDeletePropiedad"
        :loading="loading"
        toEditName="Actualizar propiedad"
        :showDestacado="false"
      />
      <ModalEditarPosicion
        :loading="loading"
        v-model="modalEditPosicion"
        :key="keyModal"
        @hidden="propiedad = { posicion: null }"
        :posicion="propiedad.posicion"
        @onChangePosicion="(e) => (propiedad.posicion = e)"
        :handleUpdatePosicion="handleUpdatePosicion"
      />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { FIELDS_TABLA_PROYECTO } from "@/constants/proyecto";
  import TablaProyectos from "@/components/tabla/proyectos";
  import ModalEditarPosicion from "@/components/modal/editarPosicion";
  import {
    MSG_SWAL_POSICION_ACTUALIZADA,
    MSG_SWAL_POSICION_ACTUALIZADA_ERROR,
    MSG_SWAL_ELIMINAR,
  } from "@/constants/swal";
  
  export default {
    components: {
      TablaProyectos,
      ModalEditarPosicion,
    },
    data() {
      return {
        loading: false,
        modalEditPosicion: false,
        propiedad: { posicion: null },
        fields: JSON.parse(JSON.stringify(FIELDS_TABLA_PROYECTO)),
        keyModal: 1,
      };
    },
    methods: {
      ...mapActions({
        getPropiedades: "propiedades/getPropiedades",
        deletePropiedad: "propiedades/deletePropiedad",
        updatePosicion: "propiedades/updatePosicion",
        updateDestacado: "propiedades/updateDestacado",
      }),
      openEditPosicion(propiedad) {
        this.propiedad = { ...propiedad };
        this.modalEditPosicion = true;
        this.keyModal++;
      },
      async handleUpdatePosicion() {
        this.loading = true;
        const res = await this.updatePosicion({
          id: this.propiedad.id,
          posicion: this.propiedad.posicion,
        });
        if (res) {
          this.$swal(MSG_SWAL_POSICION_ACTUALIZADA);
          this.modalEditPosicion = false;
        } else {
          this.$swal(MSG_SWAL_POSICION_ACTUALIZADA_ERROR);
        }
        this.loading = false;
      },
      async handleDeletePropiedad(id) {
        const result = await this.$swal.fire(MSG_SWAL_ELIMINAR);
  
        if (!result.isConfirmed) return;
  
        const res = await this.deletePropiedad(id);
  
        if (res) {
          return this.$swal("Exito", "Propiedad eliminada", "success");
        }
  
        this.$swal("Error", "No se pudo eliminar la propiedad", "error");
      },
    },
    computed: {
      ...mapGetters({
        propiedades: "propiedades/propiedades",
      }),
    },
    mounted() {
      this.getPropiedades();
    },
  };
  </script>